import React, { useEffect, useState } from 'react';
import style from './tree.module.scss';
import {TitleHeaders} from './';
import {Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ReferralService from '../../../service/tree/referralCount';
import ReactPaginate from 'react-paginate';
import Spinner from '../../shared/Spinner';

function ReferralContent(props) {
    const {t} = useTranslation();
    const history = useHistory();
    const [state,setState] = useState({
        level : 'all',
        offset : 0,
        data : [],
        count : 0,
        totalLevels : [],
        showNext : true,
        loading:true,
        searchloader:false,
    })
    useEffect(()=>{
        const service = new ReferralService();
        service.getReferral(state.level,state.offset).then(res=>{
            if(res.status){
                setState(prev=>({
                    ...prev,
                    data : res.data.tableData,
                    totalLevels : new Array(res.data.total_levels).fill().map((_, idx) => idx+1),
                    count : res.data.total_referral_count,
                    loading:false
                    // level : res.data.total_levels
                }))
            }else{
                history.push('/login');
            }
        })
    },[state.level,state.offset,history]);
    //onreset button click 
   const reset =()=>{
        setState(prev=>({
            ...prev,
            level : '',
            offset: 0
        }))
    }
    //serch the level wise list 
    const searchLevel = (level)=>{
        setState(prev=>({
            ...prev,
            searchloader:true
        }));

        setTimeout(() => {
  
            setState(prev=>({
                ...prev,
                level:level,
                searchloader:false
            }));

          }, 3000);
      
    }
    const handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 20);
        let showNext = true
        if(offset>0){
            showNext = Math.floor(state.count/offset)===1?false:true
        }
        setState(prev=>({
            ...prev,
            offset : offset,
            showNext  :showNext
        }));
      };
    return (
        <div className={style.ReferralMembers}>
            {state.loading?
            <Spinner/>

            :
            <>
            <TitleHeaders 
                tilesvalue="referralDownline"
                count={state.count}
                level={state.totalLevels}
                searchLevel={searchLevel}
                reset={reset}
                searchloader={state.searchloader}

                
            />
            <div>
                <Table striped   hover responsive className={style.tableData}>
                    <thead>
                        <tr>
                            <th>{t('Common.memberName')}</th>
                            <th>{t('Common.sponsor')}</th>
                            <th>{t('Common.level')}</th>
                            <th>{t('Common.action')}</th>
                        </tr>
                    </thead>
                    <tbody> 
                        {
                            state.data.map((items,index) =>(
                                console.log(items),
                                <tr key={index}>
                                    <td className={style.tableAvatarDetails}>
                                        <div>
                                            <img src={items.user_photo} alt="" className={style.profileImage}/>
                                            <span className={items.active=='yes' ? style.active:style.inactive}></span>
                                        </div>
                                        <div>
                                            <div className={style.userName}>{items.user_detail_name} {items.user_detail_second_name}</div>
                                            <span className={style.userid}>{items.user_name}</span>
                                        </div>
                                    </td>
                                    <td>{items.sponsor}</td>
                                    <td>{items.ref_level}</td>
                                    <td>
                                        <Link to={`/sponsorTree/${items.user_name}`} className={style.action}>
                                        <div className={style.tooltip}>
                                           <i className="fa fa-sitemap">
                                           <span className={style.tooltiptext}>{t('Common.viewspon')}</span>
                                               </i>
                                        </div>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                {
                    state.count>20&&
                            <ReactPaginate
                                previousLabel={t('<<')}
                                nextLabel={t('>>')}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={Math.ceil(state.count/20)}
                                marginPagesDisplayed={5}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={style.paginationContainer}
                                activeClassName={style.active}
                                previousLinkClassName={state.offset===0?'d-none':style.Link}
                                nextLinkClassName={!state.showNext?'d-none':style.Link}
                            />  
                }
            </div>
            </>
}
        </div>
            
    )
}

export default ReferralContent
