import React, { useCallback, useEffect, useState } from "react";
import style from "../crm/crm.module.scss";
import { Form } from "react-bootstrap";
import {
  DatePickers,
  FormInputControl,
  DropDownBs,
  CustomButton,
  AlertBs,
} from "../common";
//import moment from 'moment';
//service
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CrmService from "../../../service/crm/Crm";
import AppinfoService from "../../../service/common/Appinfo";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const InitialForm = {
  first_name: "",
  email_id: "",
  mobile_no: "",
  country: "",
  country_name: "",

  formError: {
    first_name: "",
    email_id: "",
    mobile_no: "",
    country: "",
    id: "",
  },
};
function AddForm(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();
  //const [status, setStatus] = useState(leadStatus);
  const [state, setState] = useState({
    ...InitialForm,
    alert: {
      show: false,
      message: "",
      varient: "",
    },
    editLoader: false,
  });

  const [countryValue, setCountryValue] = useState({
    value: "",
  });

  const commonService = new AppinfoService();
  //intial loading the country
  //get the country in inital loading
  const getCountry = useCallback(() => {
    commonService.getCountry().then((res) => {
      if (res.status) {
        console.log(
          "---",
          res.data.country[0].value,
          res.data.country[0].title
        );
        setState((prev) => ({
          ...prev,
          countryList: res.data.country,
          country: res.data.country[0].value,
          country_name: res.data.country[0].title,
        }));
      } else {
        if (res.error.code === 1002) {
          history.push("/logout");
        }
      }
    });
  }, [history]);
  useEffect(() => {
    getCountry();
  }, [getCountry]);

  // //get the edit data
  // useEffect(() => {
  //     const { data, id } = props;
  //     console.log(data,id,"valuesss")

  //     setState(prev => ({
  //         ...prev,
  //         id: id,
  //         ...data
  //     }));
  //     // if(id){
  //     //     const addStatus = status;
  //     //     addStatus.push({
  //     //         id : 'Accepted'
  //     //     })
  //     //     setStatus(addStatus)
  //     // }
  // }, [props])

  //changeHandler
  const changeHandler = (e) => {
    let countrydata = state.countryList.find(
      (obj) => obj.value == e.target.value
    );
    let { name, value } = e.target;

    if (name == "country") {
      console.log('"i am indside');
      console.log(e.target[value]);
      setState((prev) => ({
        ...prev,
        [name]: value,
        country_name: countrydata,
        formError: {
          ...prev.formError,
          [name]: "",
        },
      }));
    }
    console.log("valuess", value);
    setState((prev) => ({
      ...prev,
      [name]: value,
      formError: {
        ...prev.formError,
        [name]: "",
      },
    }));
  };

  //submit the
  const onSubmitForm = (e) => {
    console.log("-----", state.country_name.title);
    e.preventDefault();
    let isValid = true;
    let FormError = {};
    //validation in first nanme
    if (!state.first_name) {
      isValid = false;
      FormError.first_name = {
        error: "validation.required",
        field: {
          field: "name",
        },
      };
    }
    if (!state.country) {
      isValid = false;
      FormError.country = {
        error: "validation.required",
        field: {
          field: "country",
        },
      };
    }

    if (!state.email_id) {
      isValid = false;
      FormError.email_id = {
        error: "validation.required",
        field: {
          field: "email",
        },
      };
    }

    if (!state.mobile_no) {
      isValid = false;
      FormError.mobile_no = {
        error: "validation.required",
        field: {
          field: "mobile",
        },
      };
    }
    if (state.email_id) {
      var regexPattern = new RegExp(
        /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/
      );
      if (!regexPattern.test(state.email_id)) {
        isValid = false;
        FormError.email_id = {
          error: "validation.fieldAreNotCurrectFormat",
          field: {
            field: "email_id",
          },
        };
      }
    }
    if (state.email_id) {
      var regexPattern = new RegExp(
        /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/
      );
      if (!regexPattern.test(state.email_id)) {
        isValid = false;
        FormError.email_id = {
          error: "validation.fieldAreNotCurrectFormat",
          field: {
            field: "email_id",
          },
        };
      }
    } else if (state.mobile_no) {
      //validate the phone number
      var regexPattern = new RegExp(/^[0-9-+]+$/); // regular expression pattern
      if (!regexPattern.test(state.mobile_no)) {
        isValid = false;
        FormError.mobile_no = {
          error: "validation.fieldAreNotCurrectFormat",
          field: {
            field: "mobile_no",
          },
        };
      }
      // error : 'cannot be less than 5 characters',
      if (state.mobile_no < 15) {
        isValid = false;
        FormError.mobile_no = {
          error: "cannot be less than 15 characters",
          field: {
            field: "mobile_no",
          },
        };
      }
    }
    if (isValid) {
      let formData = new FormData();
      formData.append("name", state.first_name);
      formData.append("email", state.email_id);
      formData.append("country", state.country_name.title);
      formData.append("mobile", state.mobile_no);
      formData.append(
        "source",
        Cookies.get("source") ? Cookies.get("source") : ""
      );
      console.log("form data", formData.get("email"));

      setState((prevState) => ({
        ...prevState,
        editLoader: true,
      }));

      commonService.addNewDemoVisitor(formData).then((res) => {
        console.log("id response", res);
        localStorage.setItem("visitorid", res.data.visitor_id);
        setState((prevState) => ({
          ...prevState,
          editLoader: true,
        }));

        props.setstate((prevState) => ({
          ...prevState,
          modal: {
            title: "Enter OTP sent to your email",
            show: true,
            data: {},
            id: "",
          },
          // visitorid : res.visitor_id,
          continue: true,
        }));
      });
    } else {
      setState((prev) => ({
        ...prev,
        formError: {
          ...prev.formError,
          ...FormError,
        },
        editLoader: false,
        alert: {
          show: true,
          varient: "danger",
          message: "validation.checkValueYouHaveSubmitted",
        },
      }));
    }
  };
  //close alert
  const closeAlert = () => {
    setState((prev) => ({
      ...prev,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_panel} ${style.papanel_defaultnel}`
          : `${style.panel} ${style.papanel_defaultnel}`
      }`}
    >
      {console.log("hdfhdh", state)}
      <div className={`${style.panel_body}`}>
        <AlertBs {...state.alert} close={closeAlert} />
        <Form onSubmit={onSubmitForm}>
          <FormInputControl
            label={"Name"}
            required={true}
            inputProps={{
              type: "text",
              name: "first_name",
              placeholder: "Name",
              value: state.first_name,
              onChange: changeHandler,
            }}
            error={state.formError.first_name}
          />
          <FormInputControl
            label={"Email"}
            required={true}
            inputProps={{
              type: "text",
              name: "email_id",
              placeholder: "Email ID",
              value: state.email_id,
              onChange: changeHandler,
            }}
            error={state.formError.email_id}
          />
          <FormInputControl
            label={"Phone"}
            required={true}
            inputProps={{
              type: "text",
              name: "mobile_no",
              placeholder: "Phone",
              value: state.mobile_no,
              onChange: changeHandler,
            }}
            error={state.formError.mobile_no}
          />
          {console.log("state.country", state.country)}
          <DropDownBs
            label={"Country"}
            required={true}
            // preOption={state.country}
            options={{
              data: state.countryList,
              value: "value",
              label: "title",
            }}
            DropdownProps={{
              name: "country",
              onChange: changeHandler,
              value: state.country,
              autoComplete: "off",
            }}
            error={state.formError.country}
          />

          <CustomButton
            type="submit"
            variant="primary"
            disabled={state.editLoader}
          >
            {state.editLoader ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            ) : (
              ""
            )}

            {"Continue"}
          </CustomButton>
        </Form>
      </div>
    </div>
  );
}
export default AddForm;
