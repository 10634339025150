import React, { useEffect, useState } from 'react';
import style from './replica.module.scss';
import { Row, Col, Container, Navbar } from 'react-bootstrap';
import { Link } from "react-scroll";
import { Dropdown } from 'react-bootstrap';
import Language from './Language';
import { useTranslation, withTranslation } from 'react-i18next';



function Header(props) {
    console.log(props);
    const [show, setShow] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const { t } = useTranslation();
    const [scrollPosition, setScrollPosition] = useState(0);



    const controlNavbar = () => {

        if (typeof window !== 'undefined') {
            if (window.scrollY > lastScrollY) {
                setShow(true);
            } else {
                setShow(false);
            }
            setLastScrollY(window.scrollY);
        }
        const position = window.pageYOffset;
        console.log(lastScrollY, window.scrollY)
        setScrollPosition(position);
    }

    useEffect(() => {

        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [lastScrollY]);

    const toggleIcononly = () => {
        document.querySelector('.menubar_sec').classList.toggle('show');
    }



    return (

        <header className={style.main_header_sec} >
            <div className={`${style.top_bar_section} headershow ${show && 'headerhidden'}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className={style.top_cnt_sec}>
                                <div className={style.top_cnt_sec_box}>

                                    <Dropdown  >
                                        <Dropdown.Toggle variant="Secondary" >
                                            <Language ismainlayout={true} user_name={props.user_name} />
                                        </Dropdown.Toggle>

                                    </Dropdown>
                                </div>

                                <div className={style.top_cnt_sec_box}>
                                    <i class="fa fa-regular fa-envelope"></i> {props.data.user_details && props.data.user_details.email}
                                </div>
                                <div className={style.top_cnt_sec_box}>
                                    <i class="fa fa-solid fa-phone"></i>{props.data.user_details && props.data.user_details.phone}
                                </div>
                                <div className={style.top_cnt_sec_box}>
                                    <i class="fa fa-regular fa-user"></i> {props.data.user_details && props.data.user_details.fullname}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="main_logo"><img src={'https://admin.mlm.test/uploads/images/logos/' + props.logo} alt="" /></div>
                    </div>
                    <div className="col-md-8 mob_full_sec">
                        <nav className="navbar navbar-expand-lg navbar_dark">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                                aria-label="Toggle navigation" onClick={() => { toggleIcononly() }}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse justify-content-end menubar_sec" id="navbarNav">

                                <ul className="navbar-nav">

                                    <li className="nav-item" >
                                        {props.showmenu &&

                                            <Link className="nav-link active" to="home" spy={true} smooth={true} duration={900}>
                                                {t('Replica.home')}
                                            </Link>
                                        }

                                    </li>
                                   
                                    <li className="nav-item">
                                    {props.showmenu&&

                                        <Link className="nav-link active" to="about" spy={true} smooth={true} duration={500}>
                                            {t('Replica.about')}
                                        </Link>
                                    }
                                    </li>
                                    <li className="nav-item">
                                        {props.showmenu &&

                                            <Link className="nav-link active" to="plan" spy={true} smooth={true} duration={900}>
                                                {t('Replica.plan')}
                                            </Link>
                                        }

                                    </li>
                                    {props.showmenu&&

                                    <li className="nav-item">
                                        <Link className="nav-link active" to="contact" spy={true} smooth={true} duration={500}>
                                            {t('Replica.contact')}
                                        </Link>
                                    </li>
                                    }
                                    <li className="nav-item">
                                        <a className="nav-link login_btn" href={props.data && props.data.regsitration_url} target='_blank'> {t('Replica.register')}</a>
                                    </li>

                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

        </header>

    )
}


export default withTranslation()(Header)