import React from 'react'
import style from './Common.module.scss';
import { Card, Row, Col, Form, Button } from "react-bootstrap";
function CustomButton({children,...props}) {
    console.log(props)
    return (
        <Button   className={`${style.customBtn}  ${style[props.variant]} ${props.btnclass}`} {...props}  disabled={props.disabled ? props.disabled:false}>
            {children}
        </Button>
    )
}

export default CustomButton
