import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation }  from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import Report from '../../components/shopping/Report';
import invoiceService from '../../../service/shopping/invoice';
import companyName from '../../../store/mobxStore/companyName';


function RepurchaseReport(props){
    console.log("=====wqrwqw")
    let service = new invoiceService();
    const [state, setState] = useState({
        loader  :false,
        filter : {
            start : moment().startOf('month'),
            end: moment(),
            label : moment().startOf('month').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY')
        },
        report : {
            totalRows   : 0,
            data        : [],
            pending     : true,
            perPage     : 10,
            inPage      : 1,
        }

    })

    const buttonGroups = [
    ];
    useEffect(() => {
        loadData(state.report.inPage,state.report.perPage,state.filter.start.format('Y-MM-DD'),state.filter.end.format('Y-MM-DD'));
    }, []);

        //load the data
      const   loadData=(inPage=state.report.inPage,perPage=state.report.perPage,startDate='',endDate='')=>{
                let start = (inPage-1) * perPage;
                service.getReport(start,perPage,startDate,endDate).then(res=>{
                    if(res.status){
                        setState(prevState => ({
                            ...prevState,
                            report: {
                                ...prevState.report,
                                totalRows : res.data.total_row,
                                data : res.data.data,
                                pending : false
    
                            }
                        }))
                    }else{
                        if(res.error.code === 1002){
                            props.history.push('/logout');
                        }else if(res.error.code ===1057){
                            props.history.push({
                                pathname: '/dashboard',
                                state: {
                                    error: true,
                                    message : 'permissionDenied'
                                }
                            })
                        }
                    }
                })
        }

 //chanege filter date 
const filterChange= (start, end) => {
    setState(prevState => ({
        ...prevState,
        filter: {
            ...prevState.filter,
            start : start,
            end   :  end,
            label: start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')

        }
    }))

    };
const  resetButton = () =>{
    setState(prevState => ({
        ...prevState,
        report: {
            ...prevState.report,
            perPage     : 10,
            inPage      : 1,
        }
    }))
    loadData(1,10)
}
    //submit the filter button 
const  submitFilter = () =>{
        let StartDate = state.filter.start.format('Y-MM-DD');
        let EndDate = state.filter.end.format('Y-MM-DD');
        loadData(state.report.inPage,state.report.perPage,StartDate,EndDate);
    }

const   paginationchange =(inPage,perPage=state.report.perPage) =>{
    setState(prevState => ({
            ...prevState,
            report: {
                ...prevState.report,
                inPage : inPage,
                perPage : perPage
            }
        }));
        let StartDate = state.filter.start.format('Y-MM-DD');
        let EndDate = state.filter.end.format('Y-MM-DD');
        loadData(inPage,perPage,StartDate,EndDate);


    // setState(prevState => ({
    //     ...prevState,
    //     report: {
    //         ...prevState.report,
    //         inPage : inPage,
    //         perPage : perPage
    //     }
    // }, function() {
    //     console.log(state)
    //     loadData();
    //   }));
       
    }

    const { t } = props;
    if(state.loader) {
        return <Spinner />
    }
    return (

        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.repurchaseReport')}</title>
            </Helmet>
            <PageTitle title="repurchaseReport" buttonOn={false} group={buttonGroups}/>
            <Report 
                {...state}
                service={service}
                changeDate={filterChange}
                resetButton={resetButton}
                submitFilter={submitFilter}
                paginationchange={paginationchange}
            />
        </div>
    )


}
export default withTranslation()(withRouter(RepurchaseReport));