import React, { useState } from 'react';
import style from './payment.module.scss';
import {Form,Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Payments from '../../../service/common/PaymentApi';
import ReplicaRegService from '../../../service/Auth/Replicareg';
function Epin(props) {
    const service = new Payments();
    const {t} = useTranslation();
    const currency = useSelector(state=>state.curr);
    const [state,setState] = useState({
        data : [],
        isvalid : false,
        epin : '',
        error : '',
        totalAmount : 0
    })
    //validate epin
    const validateEpin = () =>{
        if(state.epin){
            if(state.data.some(element=>element.pin === state.epin)){
                setState(prev=>({
                    ...prev,
                    error : 'epinRepeated'
                }))
            }else{
                validateEpinAPI(state.data,true)
            }
        }else{
            setState(prev=>({
                ...prev,
                error : 'enterEpin'
            }))
        }
    }


    //change Epin field
    const changeHandler = e =>{
        const {value} = e.target;
        setState(prev=>({
            ...prev,
            epin : value
        }))
    }
    //remove epin list 
    const removeEpin = index =>{
        if(state.data.length===1){
            setState(prev=>({
                ...prev,
                data : [],
                isvalid  : false
            }))
        }else{
            let epinList  = state.data;
            epinList.splice(index,1);
            validateEpinAPI(epinList);
        }
    }
    const validateEpinAPI = (data,add=false) =>{
        let pin_array = data.map((list,index)=>{
            return{
                ...list,
                i: index+1
            }
        });
        if(add){
            pin_array[pin_array.length] = {
                pin : state.epin,
                amount : 0,
                i : pin_array.length+1
            }
        }
        let formData = {
            pin_array : pin_array,
            product_id : props.product,
            payment_type: props.payment_type
        }
       if(window.location.pathname.includes('/replica_register')) {
        const service=new ReplicaRegService();
        formData.sponser_id=props.sponser_id
        service.checkepinAvailability(formData).then(res=>{
            if(res.status){
                let isvalid =false;
                if(res.data.some(element=>element.pin === 'nopin')){
                    setState(prev=>({
                        ...prev,
                        error : 'invalidEpin'
                    }))
                }else{
                    let total_amount = 0
                    let resultData = res.data.map((pinResult)=>{
                        console.log("=====",pinResult)
                        total_amount+=pinResult.epin_used_amount
                        if(pinResult.reg_balance_amount===0){
                            isvalid = true
                        }
                             return {
                                    pin : pinResult.pin,
                                    amount : pinResult.epin_used_amount,
                                    i : pinResult.i
                                }
                        })
                        setState(prev=>({
                            ...prev,
                            data : resultData,
                            epin : "",
                            error : "",
                            isvalid : isvalid,
                            totalAmount : total_amount
                        }));
                        props.epin(resultData);
                }
            }
        })
        }else{
            service.epinAvailability(formData).then(res=>{
                if(res.status){
                    let isvalid =false;
                    if(res.data.some(element=>element.pin === 'nopin')){
                        setState(prev=>({
                            ...prev,
                            error : 'invalidEpin'
                        }))
                    }else{
                        let total_amount = 0
                        let resultData = res.data.map((pinResult)=>{
                            console.log("=====",pinResult)
                            total_amount+=pinResult.epin_used_amount
                            if(pinResult.reg_balance_amount===0){
                                isvalid = true
                            }
                                 return {
                                        pin : pinResult.pin,
                                        amount : pinResult.epin_used_amount,
                                        i : pinResult.i
                                    }
                            })
                            setState(prev=>({
                                ...prev,
                                data : resultData,
                                epin : "",
                                error : "",
                                isvalid : isvalid,
                                totalAmount : total_amount
                            }));
                            props.epin(resultData);
                    }
                }
            })
        }
       
    }
    return (
        <div className={style.MianContent}>
            <div className={style.epinContent}>
                <div className={style.epinList}>
                    {
                        state.data.length===0?
                        <div className={style.noDataList}>{t('Common.noEpinApplied')}</div>:
                        state.data.map((lists,index)=>(
                            <div key={index} className={style.listView}>
                               <b>{lists.pin}</b> 
                               <span className={style.epinAmount}>
                                    <b>{currency.currentCurr}{(lists.amount*currency.value).toFixed(currency.precision)}</b>
                                    <span className={style.removeButton} onClick={()=>removeEpin(index)}>
                                        <i className="fa fa-times"></i>
                                    </span>
                               </span>
                            </div>
                        ))
                    }
                    {
                        state.data.length!==0&&
                        <div className={style.epinTotoalamount}>
                            <b>{t('Common.totalAmount')+ ' '}</b>
                            <span className={style.amount}>
                                {currency.currentCurr}{(state.totalAmount*currency.value).toFixed(currency.precision)}
                            </span>
                        </div>
                    }
                </div>
            </div>
        

            <div className={style.ButtonGroup}>
                {
                    !state.isvalid&&
                    <div>
                        <div className={`${style.inLineBlock}`}>
                            <Form.Control  className={style.inputField} placeholder={t('Common.enterEpin')} value={state.epin} onChange={changeHandler}  />
                        </div>
                        <div className={`${style.inLineBlock}`}>
                            <Button className={style.ApplyButton} onClick={validateEpin}>{t('Button.apply') }</Button>
                        </div>
                        {
                            state.error&&
                             <div className={style.invalid}>
                                 <Form.Text>{t('validation.'+state.error)}</Form.Text>
                             </div>
                         }
                    </div>
                }
                <div className={style.Buttons}>
                    {
                        props.prevButtonClick&&
                        <Button onClick={props.prevButtonClick} className={style.PrevButton}>{t('profile.prev')}</Button>
                    }
                    <Button disabled={!state.isvalid} type="submit" className={style.NextButton}>{t('Button.finish') }</Button>
                </div>
            </div>
        </div>
    )
}

export default Epin
