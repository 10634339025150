import React, { useState, useEffect } from "react";
import style from "./dashboard.module.scss";
import "./Earnandperform.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "react-responsive-tabs/styles.css";
import profilepic from "../../../assets/images/dashboard/prf.png";
import { BASE, IMG_URL } from "../../../configuration/apiconfig";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

//import { CurrencyDecimalFormat } from '../../helper';

// const brdercontent = {
//     "marginBottom": "20px",
//     "paddingBottom": "30px",
//     "borderBottom": "1px #e5e5e5 solid",
// }

function TeamPerformanceNew({
  topEarners,
  topRecruters,
  packageoverviews,
  rankOverviews,
  loadervalue,
  data,
}) {
  //income,expenses,payoutstatus

  console.log(loadervalue);

  console.log("=====908765433", data);
  const { currentTheme } = useSelector((state) => state.Theme);
  const [toggleState, setToggleState] = useState(1);
  const [tabs, setTabs] = useState([]);
  const [content, setContent] = useState("topEarners");
  const curr = useSelector((state) => state.curr);

  const { t } = useTranslation();

  const toggleTab = (index, content) => {
    setToggleState(index);
    setContent(content);
  };

  const [state, setState] = useState({
    topdata: [],
    toprecruiterdata: [],
    packageOverview: [],
    rankOverview: [],
  });
  const { topdata, toprecruiterdata, packageOverview, rankOverview } = state;

  const remove_duplicates = (array_) => {
    var ret_array = [];
    for (var a = array_.length - 1; a >= 0; a--) {
      for (var b = array_.length - 1; b >= 0; b--) {
        if (array_[a] === array_[b] && a !== b) {
          delete array_[b];
        }
      }
      if (array_[a] !== undefined) ret_array.push(array_[a]);
    }
    return ret_array;
  };

  useEffect(() => {
    if (rankOverviews) {
      setState((prevstate) => ({
        ...prevstate,
        rankOverview: rankOverviews,
      }));
      setTabs((prevstate) => [...prevstate, "rankOverview"]);
    }
    if (packageoverviews) {
      setState((prevstate) => ({
        ...prevstate,
        packageOverview: packageoverviews,
      }));
      setTabs((prevstate) => [...prevstate, "packageOverview"]);
    }
    if (topRecruters) {
      setState((prevstate) => ({
        ...prevstate,
        toprecruiterdata: topRecruters,
      }));
      setTabs((prevstate) => [...prevstate, "topRecruiters"]);
    }
    if (topEarners) {
      setState((prevstate) => ({
        ...prevstate,
        topdata: topEarners,
      }));
      setTabs((prevstate) => [...prevstate, "topEarners"]);
    }
    // managefunction()
  }, [topEarners, topRecruters, packageoverviews, rankOverviews]);

  return (
    <div className={style.dashbrd_earing_exp_sec}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_dashboard_pannel_box}`
            : `${style.dashboard_pannel_box}`
        }`}
      >
        {data.lodervalue ? (
          <>
            <div className={style.dashboard_new_member_head}>
              <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton width={250} count={1} />
              </SkeletonTheme>
            </div>
            <div className="earn_n_team_content-tabs">
              <div className={style.dash_perfomencers_row}>
                <div className={style.dash_perfomencers_prf_ico}>
                  {/* <Skeleton width={120} circle={true} /> */}
                </div>
                <div className={style.dash_perfomencers_name}>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton width={120} count={1} />
                  </SkeletonTheme>
                  {/* <span><Skeleton width={50} count={1} /></span> */}
                </div>
                {/* <div className={style.dash_perfomencers_amnt}> <Skeleton width={40} count={1} /> </div> */}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_dashboard_new_member_head}`
                  : `${style.dashboard_new_member_head}`
              }`}
            >
              {t("Dashboard.teamPerformance")}
            </div>
            <div className={style.dash_perfomencers}>
              <div className="earn_n_team_bloc-tabs">
                {remove_duplicates(tabs).map((obj, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        toggleState === index + 1
                          ? "earn_n_team_tabs earn_n_team_activee-tabs"
                          : "earn_n_team_tabs"
                      }
                      onClick={() => toggleTab(index + 1, obj)}
                    >
                      {t("Common." + obj)}
                    </div>
                  );
                })}
              </div>
              <div className="earn_n_team_content-tabs">
                <div
                  className={
                    content === "topEarners"
                      ? "earn_n_team_content  earn_n_team_activee-content"
                      : "earn_n_team_content"
                  }
                >
                  {topdata.length > 0 ? (
                    topdata.map((obj, index) => {
                      return (
                        <div key={index}>
                          <div className={style.dash_perfomencers_row}>
                            <div className={style.dash_perfomencers_prf_ico}>
                              <img src={profilepic} alt="Pic" />
                            </div>
                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dash_perfomencers_name}`
                                  : `${style.dash_perfomencers_name}`
                              }`}
                            >
                              {obj.user_detail_name +
                                " " +
                                obj.user_detail_second_name}
                              <span>{obj.user_name}</span>
                            </div>
                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dash_perfomencers_amnt}`
                                  : `${style.dash_perfomencers_amnt}`
                              }`}
                            >
                              {" "}
                              {curr.currentCurr}{" "}
                              {(obj.balance_amount * curr.value).toFixed(
                                curr.precision
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={`${style.NodataImage}`}>
                      <center>
                      <img
                        src={IMG_URL + "logos/no-datas-found.png"}
                        alt="nodata"
                        style={{ width: "50%" }}
                      />
                      </center>
                
                    </div>
                  )}
                </div>

                <div
                  className={
                    content === "topRecruiters"
                      ? "earn_n_team_content  earn_n_team_activee-content"
                      : "earn_n_team_content"
                  }
                >
                  {toprecruiterdata.length > 0 ? (
                    toprecruiterdata.map((obj, index) => {
                      return (
                        <div key={index}>
                          <div className={style.dash_perfomencers_row}>
                            <div className={style.dash_perfomencers_prf_ico}>
                              <img src={profilepic} alt="" />
                            </div>
                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dash_perfomencers_name}`
                                  : `${style.dash_perfomencers_name}`
                              }`}
                            >
                              {obj.user_detail_name +
                                " " +
                                obj.user_detail_second_name}
                              <span>{obj.user_name}</span>
                            </div>
                            <div className={style.dash_perfomencers_amnt}>
                              {" "}
                              <span>{obj.count}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className={`${style.NodataImage}`}
                      style={{ textAlign: "center" }}
                    >
                      <center>
                      <img
                        src={IMG_URL + "logos/no-datas-found.png"}
                        alt="nodata"
                        style={{ width: "50%" }}
                      />
                      </center>
                     
                    </div>
                  )}
                </div>

                <div
                  className={
                    content === "packageOverview"
                      ? "earn_n_team_content  earn_n_team_activee-content"
                      : "earn_n_team_content"
                  }
                >
                  {packageOverview.length > 0 ? (
                    packageOverview.map((obj, index) => {
                      return (
                        <div key={index}>
                          <div className={style.dash_perfomencers_row}>
                            <div className={style.dash_perfomencers_prf_ico}>
                              <img src={profilepic} alt="" />
                            </div>
                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dash_perfomencers_name}`
                                  : `${style.dash_perfomencers_name}`
                              }`}
                            >
                              {obj.package_name}
                              <br />{" "}
                              <span>
                                {t("Dashboard.packageOverView", {
                                  count: obj.joining_count,
                                  packagename: obj.package_name,
                                })}
                              </span>
                            </div>

                            <div className={style.dash_perfomencers_amnt}>
                              <span>{obj.joining_count}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className={`${style.NodataImage}`}
                      style={{ textAlign: "center" }}
                    >
                      <center>
                      <img
                        src={IMG_URL + "logos/no-datas-found.png"}
                        alt="nodata"
                        style={{ width: "50%" }}
                      />

                      </center>
                
                    </div>
                  )}
                </div>
                <div
                  className={
                    content === "rankOverview"
                      ? "earn_n_team_content  earn_n_team_activee-content"
                      : "earn_n_team_content"
                  }
                >
                  {rankOverview.length > 0 ? (
                    rankOverview.map((obj, index) => {
                      return (
                        <div key={index}>
                          <div
                            style={{ padding: "0" }}
                            className={style.dash_perfomencers_row}
                          >
                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dash_perfomencers_name}`
                                  : `${style.dash_perfomencers_name}`
                              }`}
                            >
                              {obj.rank_name}
                              <br />{" "}
                              <span>
                                {t("Dashboard.rankOverview", {
                                  count: obj.count,
                                  rank: obj.rank_name,
                                })}
                              </span>
                            </div>
                            <div className={style.dash_perfomencers_amnt}>
                              <span>{obj.count}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className={`${style.NodataImage}`}
                      style={{ textAlign: "center" }}
                    >
                      <center>
                      <img
                        src={IMG_URL + "logos/no-datas-found.png"}
                        alt="nodata"
                        style={{ width: "50%" }}
                      />
                      </center>

                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TeamPerformanceNew;
