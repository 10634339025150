import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
//components
import PageTitle from "../../components/common/pageTitle";
import { Tiles, Content } from "../../components/payout";
import ModalForms from "../../components/payout/ModalForms";
import AlertMessage from "../../components/common/AlertMessage";
//style
import style from "../../components/payout/payout.module.scss";
//service
import PayoutService from "../../../service/payout";
import companyName from "../../../store/mobxStore/companyName";
import Spinner from '../../shared/Spinner';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


function Payout(props) {

    const { t } =  props;
    const [state, setState] = useState({

      requestModal: false,
      tilesData: [],
      notifyOn: false,
      requestBtn: false,
      loading:true,
      notify: {
        message: "",
        type: "",
        header: "",
      },
      DataTables: {
        key: "paid",
        pending: {
          totalRows: 0,
          data: [],
          pending: true,
          perPage: 10,
          inpage: 1,
          selectedRows: [],
          closePopUp: false,
        },
        approved: {
          totalRows: 0,
          data: [],
          pending: true,
          perPage: 10,
          inpage: 1,
        },
        paid: {
          totalRows: 0,
          data: [],
          pending: true,
          perPage: 10,
          inpage: 1,
        },
        rejected: {
          totalRows: 0,
          data: [],
          pending: true,
          perPage: 10,
          inpage: 1,
        },
      },
    
    });

    
    const [loader,setLoader] = useState(
      {
          search : false,
          reset : false,
          excel : false,
          csv : false
   })




    const service = new PayoutService();

    useEffect(() => {
         loadData();
    },[]);


    const Translation=(data)=> Object.keys(data).map((item)=>{ return t("Common."+data[item])})

    const  ExportToExcel = (apiData, fileName, type) => {

      let fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let fileExtension = ".xlsx";

      if (type === "excel") {
          // const exportToCSV = (apiData, fileName) => {
          const ws = XLSX.utils.json_to_sheet(apiData);
          console.log(ws)
          if(apiData.length > 0){
          XLSX.utils.sheet_add_aoa(ws, [Translation(Object.keys(apiData[0]))]);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, fileName + fileExtension);
          setLoader({
            search :false,
            reset : false,
            excel : false,
            csv : false
          })
        }
        else{
          setLoader({
            search :false,
            reset : false,
            excel : false,
            csv : false
          })
        }
          // };
      } else if (type === "csv") {
          fileExtension = ".csv";
          let fileType = "text/csv;charset=utf-8";
          const ws = XLSX.utils.json_to_sheet(apiData);
          if(apiData.length > 0){
          XLSX.utils.sheet_add_aoa(ws, [Translation(Object.keys(apiData[0]))])
          const csvOutput = XLSX.utils.sheet_to_csv(ws);
          const data = new Blob([csvOutput], { type: fileType });
          FileSaver.saveAs(data, fileName + fileExtension);
          setLoader({
            search :false,
            reset : false,
            excel : false,
            csv : false
          })
        }
        else{
          setLoader({
            search :false,
            reset : false,
            excel : false,
            csv : false
          })
        }
      } else if (type === "print") {
        
          //  let printView = <UserEarningsPrintView
          //      data={apiData}
          // />
          
      }

  };

  const removeData = data =>{
    let newdata = data.filter((item,key)=>{

      
      delete item['request_id']

      return t(item);
    })

    

    return newdata;



  }
  // Export Data
const  exportData = type => { 
  if (type === 'excel') {
    setLoader({
      search :false,
      reset : false,
      excel : true,
      csv : false
    })}
    else{
      setLoader({
        search :false,
        reset : false,
        excel : false,
        csv : true
      })
    }
 if(state.DataTables.key === 'pending'){
  service.PendingTable(0,100, "","")
  .then(res => {
              if (type === 'excel') {
                  ExportToExcel(removeData(res.data.table_data), 'Payout Pending', type);
              }
              if (type === 'csv') {
                  ExportToExcel(removeData(res.data.table_data), 'Payout Pending', type);
              }
})
 }
 
 else if(state.DataTables.key === 'approved'){
 service.ApprovedTable(0,100, "","")
.then(res => {
            if (type === 'excel') {
                ExportToExcel(res.data.table_data, 'Payout Approved', type);
            }
            if (type === 'csv') {
                ExportToExcel(res.data.table_data, 'Payout Approved', type);
            }
})
 }
 else if(state.DataTables.key === 'paid'){
service.PaidTable(0,100, "","")
  .then(res => {
              if (type === 'excel') {
                  ExportToExcel(res.data.table_data, 'Payout Approved Paid', type);
              }
              if (type === 'csv') {
                  ExportToExcel(res.data.table_data, 'Payout Approved Paid', type);
              }
})
 }
 else if(state.DataTables.key === 'rejected'){
service.RejectedTable(0,100, "","")
  .then(res => {
              if (type === 'excel') {
                  ExportToExcel(res.data.table_data, 'Payout Rejected', type);
              }
              if (type === 'csv') {
                  ExportToExcel(res.data.table_data, 'Payout Rejected', type);
              }
})
  } 
  
}


  //initial loading
  const loadData = () => {
    service.PayoutTiles().then((res) => {
      if (res.status) {
         setState((prevState) => ({
             ...prevState,
          requestBtn: res.data.button_show,
          tilesData: res.data.payout_tile,
          loading:false
        }));
      }
    });
     getPaidTable();
    //  getPendingTable()
  };
  //get the pending table
  const getPendingTable = (
    inPage =  state.DataTables.pending.inpage,
    perPage =  state.DataTables.pending.perPage,
    order = "",
    dierection = ""
  ) => {
    let start = (inPage - 1) * perPage;
    service.PendingTable(start, perPage, order, dierection).then((res) => {
      if (res.status) {

        setLoader({
          search :false,
          reset : false
        })

         setState((prevState) => ({
             ...prevState,
          DataTables: {
            ...prevState.DataTables,
            pending: {
              ...prevState.DataTables.pending,
              totalRows: res.data.count,
              data: res.data.table_data,
              pending: false,
            },
          },
        }));
      }
    });
  };




  //pagination change
  const changePendingTable = (inPage, perPage, order = "", dierection = "") => {
     setState((prevState) => ({
         ...prevState,
      DataTables: {
        ...prevState.DataTables,
        pending: {
          ...prevState.DataTables.pending,
          perPage: perPage,
          inPage: inPage,
          pending: true,
        },
      },
    }));
     getPendingTable(inPage, perPage, order, dierection);
  };

  //close the selected popup
  const cancelPopup = (row, isCancel) => {
     setState((prevState) => ({
         ...prevState,
      DataTables: {
        ...prevState.DataTables,
        pending: {
          ...prevState.DataTables.pending,
          selectedRows: row,
          closePopUp: isCancel,
        },
      },
    }));
  };

  //cancel submit
  const CancelSubmit = () => {
    let cancelationData = new FormData();
     state.DataTables.pending.selectedRows.forEach((element) => {
      cancelationData.append("payouts[]", element);
    });
    service.CancelRequest(cancelationData).then((res) => {
      if (res.status) {
         setState((prevState) => ({
          ...prevState,
          notifyOn: true,
          notify: {
            ...prevState.notify,
            message: res.data.message,
            type: "success",
            header:  props.t("Common.Success"),
          },
          DataTables: {
            ...prevState.DataTables,
            pending: {
              ...prevState.DataTables.pending,
              selectedRows: [],
              closePopUp: false,
            },
          },
        }));

         getPendingTable();
         loadData();
      } else {
        if (res.error.code === 1019) {
        }
      }
    });
  };

   const getApprovedTable = (
    inPage =  state.DataTables.approved.inpage,
    perPage =  state.DataTables.approved.perPage,
    order = "",
    dierection = ""
  ) => {
    let start = (inPage - 1) * perPage;
    service
      .ApprovedTable(start, perPage, order, dierection)
      .then((res) => {
        if (res.status) {
          setLoader({
            search :false,
            reset : false
          })
  
           setState((prevState) => ({
               ...prevState,
            DataTables: {
              ...prevState.DataTables,
              approved: {
                ...prevState.DataTables.approved,
                totalRows: res.data.count,
                data: res.data.table_data,
                pending: false,
              },
            },
          }));
        }
      });
  };
  //change Approved Table paignation change
  const changeApprovedTable = (inPage, perPage, order = "", dierection = "") => {
     setState((prevState) => ({
         ...prevState,
      DataTables: {
        ...prevState.DataTables,
        approved: {
          ...prevState.DataTables.approved,
          perPage: perPage,
          inPage: inPage,
          pending: true,
        },
      },
    }));
     getApprovedTable(inPage, perPage, order, dierection);
  };

  //get Paid table datat
 const getPaidTable = (
    inPage =  state.DataTables.paid.inpage,
    perPage =  state.DataTables.paid.perPage,
    order = "",
    dierection = ""
  ) => {
    let start = (inPage - 1) * perPage;
    service.PaidTable(start, perPage, order, dierection).then((res) => {
      if (res.status) {
        setLoader({
          search :false,
          reset : false
        })

         setState((prevState) => ({
             ...prevState,
          DataTables: {
            ...prevState.DataTables,
            paid: {
              ...prevState.DataTables.paid,
              totalRows: res.data.count,
              data: res.data.table_data,
              pending: false,
            },
          },
        }));
      }
    });
  };

  //change paid data table
  const changePaidTable = (inPage, perPage, order = "", dierection = "") => {
     setState((prevState) => ({
         ...prevState,
      DataTables: {
        ...prevState.DataTables,
        paid: {
          ...prevState.DataTables.paid,
          perPage: perPage,
          inPage: inPage,
          pending: true,
        },
      },
    }));
    getPaidTable(inPage, perPage, order, dierection);
  };

  //get the rejected table data
  const getRejectedTable = (
    inPage =  state.DataTables.rejected.inpage,
    perPage =  state.DataTables.rejected.perPage,
    order = "",
    dierection = ""
  ) => {
    let start = (inPage - 1) * perPage;
    service
      .RejectedTable(start, perPage, order, dierection)
      .then((res) => {
        if (res.status) {
          setLoader({
            search :false,
            reset : false
          })
  
           setState((prevState) => ({
               ...prevState,
            DataTables: {
              ...prevState.DataTables,
              rejected: {
                ...prevState.DataTables.rejected,
                totalRows: res.data.count,
                data: res.data.table_data,
                pending: false,
              },
            },
          }));
        }
      });
  };

  //change rejecte
 const changeRejectedTable = (inPage, perPage, order = "", dierection = "") => {
     setState((prevState) => ({
         ...prevState,
      DataTables: {
        ...prevState.DataTables,
        rejected: {
          ...prevState.DataTables.rejected,
          perPage: perPage,
          inPage: inPage,
          pending: true,
        },
      },
    }));
     getRejectedTable(inPage, perPage, order, dierection);
  };

  //tab change
 const changePageDrop = (key) => {
     setState((prevState) => ({
         ...prevState,
      DataTables: {
        ...prevState.DataTables,
        key: key,
      },
    }));


    if (key === "pending") {
       getPendingTable();
    } else if (key === "approved") {
       getApprovedTable();
    } else if (key === "paid") {
       getPaidTable();
    } else if (key === "rejected") {
       getRejectedTable();
    }
  };

  //modal open
 const requestModalOpen = () => {
     setState((prevState) => ({
         ...prevState,
       requestModal: true,
     }));
  };

  //close Modal
  const handleClose = () => {
     setState((prevState) => ({
         ...prevState,
       requestModal: false,
     }));
  };

  //notifydismissed
  const notifyDissmissed = () => {
     setState((prevState) => ({
      ...prevState,
      notifyOn: false,
    }));
  };


const buttonGroups = [
  {
    name: "payoutRequest",
    click: requestModalOpen,
  },
];


const paginationHandler = {
  pending: changePendingTable,
  approved: changeApprovedTable,
  paid: changePaidTable,
  rejected: changeRejectedTable,
  cancel: cancelPopup,
  submit: CancelSubmit,
};

 
  
    return (
      <div className="h-100">
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.payout")}
          </title>
        </Helmet>
        <PageTitle
          title="payout"
          buttonOn={ state.requestBtn}
          group={ buttonGroups}
        />
        {state.loading ?
        <Spinner/>
        :
        <div className={style.MainContainer}>
          <Tiles tilesData={ state.tilesData} />
          <Content
            tableData={ state.DataTables}
            changeHandler={ changePageDrop}
            paginationHandler={ paginationHandler}
            exportData={exportData}
            loader={loader}
          />
          {/* payout requested modal */}
          <Modal
            show={ state.requestModal}
            onHide={ handleClose}
            dialogClassName={`${
              style.ModalDialog
            } animate__animated animate__fadeInRight ${
               state.requestModal
                ? "animate__fadeInRight"
                : "animate__fadeOutRight"
            } FundTransferModal`}
          >
            <Modal.Header closeButton bsPrefix={style.ModalHeader}>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={style.ModalBodyHeader}>
                <h3>{t("payout.payourRequest")}</h3>
              </div>
              <ModalForms
                onClose={ handleClose}
                submitSuccess={ loadData}
              />
            </Modal.Body>
          </Modal>
          { state.notifyOn && (
            <AlertMessage
              message={ state.notify.message}
              dismiss={ notifyDissmissed}
              type={ state.notify.type}
              header={ state.notify.header}
              show={state.notifyOn}
            />
          )}
        </div>
}
      </div>
    );
  
}
export default withTranslation()(Payout);
