import React, { useEffect, useState } from "react";
import { FormHead, FormsControl, Buttons } from ".";
import style from "./profile.module.scss";
import ProfileService from "../../../service/profile";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
function QrUpload(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const [data, setData] = useState(props.data);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imgPreview, setimgPreview] = useState(null)
  const [filename, setfilename] = useState(null)
  const [state, setState] = useState({})

  const history = useHistory();
  const service = new ProfileService();


  const changeHandler = (e, isReq) => {
    const { name, value } = e.target;
    setData(
      [...data].map((object) => {
        if (object.code === name) {
          if (
            name === "accountHolder" ||
            name === "bankName" ||
            name === "branchName" ||
            name === "pan"
          ) {
            return {
              ...object,
              value: value,
              error: value.length < 3 && "validation.min_length",
              errorField: {
                field: name,
                length: 3,
              },
            };
          }
          return {
            ...object,
            value: value,
            error: value.length === 0 && isReq ? name : "",
          };
        } else return object;
      })
    );
  };
  const cancelButtonClick = () => {
    setData(props.data);
    props.cancelbtn();
  };

  const fileHandler = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setfilename(event.target.files[0].name)
    setimgPreview(URL.createObjectURL(file))

  };

  const postData = () => {
    const formData = new FormData();
    formData.append("image", selectedFile)
    // formData.append("personal_info", info)
    // formData.append("replicaName", inputData)
    service.walletData(formData).then((res) => {

      if (res.status) {
        // setalert((prev) => ({
        //     ...prev,
        //     show: true,
        //     type: 'success',
        //     message: t('Common.uploadedSuccess')
        // }))
        setState((prev) => ({
          ...prev,
          qrImage: res.data?.qr_image
        }))
        props.showNotify(
          "success", 
          "success",
          "File uploaded successfully")
        getData()

        props.cancelbtn();

      } else {
        // setalert((prev) => ({
        //     ...prev,
        //     show: true,
        //     type: 'danger',
        //     message: res.error.description
        // }))
        props.showNotify(
          "danger",
          "error",
          "Please Choose a Image"
        );
      }
    })
  }

  const getData = () => {
    service.getDetails().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          qrImage: res.data?.edit_fields?.qr_img
        }))
      } else {
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const onSubmit = () => {
    let error = false;
    let formdata = {};
    data.forEach((element) => {
      if (element.error) {
        error = true;
      } else {
        formdata = {
          ...formdata,
          [element.code]: element.value,
        };
      }
    });
    if (!error) {
      const service = new ProfileService();
      service.UpdateBankDetails(formdata).then((res) => {
        if (res.status) {
          if (res.data.status) {
            console.log('daaata', data);
            props.cancelbtn();
            props.updateSuccess(data, props.stateKey);
            props.showNotify(
              "success",
              "success",
              "profile." + res.data.message
            );
            // props.getData();
          }
        } else {
          let NewData;
          if (res.error.code === 1004) {
            Object.keys(res.error.fields).map((key) => (
              NewData = data.map((obj) => {
                if (Object.keys(res.error.fields).includes(obj.code)) {
                  return {
                    ...obj,
                    error: "validation." + res.error.fields[key],
                    errorField: {
                      ...obj,
                      field: obj.code,
                      length:
                        res.error.fields[key + "_err"] === "min_length"
                          ? 3
                          : 32,
                    },
                  }
                }
                else return obj
              })
            ))
            setData(NewData)

            // Object.keys(res.error.fields).map((key) => { 
            //  NewData =   data.map((object) => {
            //     if (object.code === key) {
            //       return {
            //         ...object,
            //         error: "validation." + res.error.fields[key] + "_err",
            //         errorField: {
            //           ...object,
            //           field: key,
            //           length:
            //             res.error.fields[key + "_err"] === "min_length"
            //               ? 3
            //               : 32,
            //         },
            //       };
            //     } else return object;
            //   })


            // })
            // Object.keys(res.error.fields).map((key) => {
            //   console.log("key error",key);
            //   setData(
            //     [...data].map((object) => {
            //       if (object.code === key) {
            //         console.log("selected value is",object.code);
            //         return {
            //           ...object,
            //           error: "validation." + res.error.fields[key] + "_err",
            //           errorField: {
            //             ...object,
            //             field: key,
            //             length:
            //               res.error.fields[key + "_err"] === "min_length"
            //                 ? 3
            //                 : 32,
            //           },
            //         };
            //       } else return object;
            //     })
            //   );
            //   return null;
            // });
            props.showNotify(
              "danger",
              "error",
              "validation.checkValueYouHaveSubmitted"
            );
          } else if (res.error.code === 1002) {
            history.push("/logout");
          }
        }

        // props.cancelbtn();
      });
    }
  };
  return (
    <div className={`${currentTheme == "theme-dark"
      ? `${style.dark_mode_BankDetials}`
      : `${style.BankDetials}`
      }`}>
      <FormHead
        title="walletCode"
        change={props.change}
        status={props.status}
        show={props.isEditable}
      />
      {/* {data.map((forms, index) => {
        return ( */}
      {/* // <FormsControl
          //   key={index}
          //   change={changeHandler}
          //   status={props.isEditable}
          //   data={forms}
          // /> */}
      <Form.File
        type="file"
        disabled={props.isEditable ? true : false}
        name="profilePic"
        label={filename ? filename : 'Choose Your QR Image'}
        onChange={fileHandler}
        custom
        style={{ marginBottom: '10px' }}
      />

      {state.qrImage &&
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
          <img style={{ width: '15rem', height: '15rem' }} src={state.qrImage} alt="" />
        </div>
      }
      {/* );
       })} */}
      {!props.isEditable && (
        <Buttons cancel={cancelButtonClick} submitHandler={postData} />
      )}
    </div>
  );
}

export default QrUpload;
