import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
function stripewaitingPage() {
  return (
    <div>
        <h4>Please wait.....</h4>
       <div>
       <Spinner animation="grow" /></div> 
    </div>
  )
}

export default stripewaitingPage