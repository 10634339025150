import React from 'react';
import {Alert,Card,Col,Row,Table} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useTranslation}from 'react-i18next';
import style from './registration.module.scss';
import {Link} from 'react-router-dom';
import { IMG_URL } from '../../../configuration/apiconfig';
import { AlertBs } from '../common';
function PreviewContent(props) {
    console.log('previewco',props)
    const {t} = useTranslation();
    const currency  = useSelector(state=>state.curr);
    //print the content
    const printData = () =>{
        var myPrintContent = document.getElementById('print_area');
        var myPrintWindow = window.open("", "Print Report", 'left=300,top=100,width=700,height=500', '_blank');
        myPrintWindow.document.write(myPrintContent.innerHTML);
        myPrintWindow.document.close();
        myPrintWindow.focus();
        myPrintWindow.print();
        myPrintWindow.close();
    return false;
    }
    return (
        <div className={style.MainContainer}>
            <div className={style.previewContent}>
                <Alert variant="success" show={props.alertmessage?true:false} dismissible>
                
                
                { 
                        props.alertmessage && !props?.buttonshow &&
                        <div dangerouslySetInnerHTML={{__html:t('registration.'+props.alertmessage.message,{name:props.alertmessage.username})}}></div>                        
                    }
                    { 
                        props.alertmessage && props?.buttonshow &&
                        <div dangerouslySetInnerHTML={{__html:t('registration.'+props.alertmessage.message,{name:props.alertmessage.username})}}></div>                        
                    }
                </Alert>
                <div className={style.buttonsActions}>
                    {!props?.buttonshow &&
                    <Link className={`${style.button} btn m-b-xs btn-sm btn-primary`} to="/genealogyTree">{t('Common.gotoTreeView')}</Link>
                      }
                    <button className={`${style.button} btn m-b-xs btn-sm btn-primary`} onClick={printData}>
                        <i className="fa fa-print"></i>
                        {t('Common.print')}
                    </button>
                </div>
                <Card id="print_area">
                    <Card.Body className={style.cardBody}>
                        <Row>
                            <Col sm={6}>
                                <div className={style.img}>
                                    <img 
                                        src={`${IMG_URL}logos/logo_login.png`}
                                        alt=""/>
                                </div>
                            </Col>
                            <Col sm={6} className={style.companyInfo}>
                                <p>Company Name</p>
                                <p>Company Address</p>
                            </Col>
                            <Col sm={12}>
                            <Table striped responsive>
                                <tbody>
                                    {
                                        props.data.data.user_info&&
                                        props.data.data.user_info.map((items,index)=>{
                                            return <tr key={index}>
                                                        <td><strong>{t('Common.'+items.code)}</strong></td>
                                                        <td>
                                                            {
                                                                items.amount?

                                                                
                                                            <span>{currency.currentCurr} {(currency.value*items.amount).toFixed(currency.precision)}</span>:
                                                                <span>{items.value}</span>
                                                            }
                                                        </td>
                                                    </tr>
                                        })
                                    }
                                </tbody>
                                </Table>
                            </Col>
                            <Col sm={12}>
                                {
                                   props.data.data.letter &&
                                    <div className={style.regards}>
                                        <span dangerouslySetInnerHTML={{__html:props.data.data.letter.content}}></span>
                                        <br/>
                                            <span>{t('Common.winningRegard')}</span>
                                        <br/>
                                        <br/>
                                            <span>{t('Common.admin')}</span>
                                        <br/>
                                        <br/>
                                            <span>{props.data.data.letter.companyName}</span>
                                        <br/>
                                        <br/>
                                            <span>{t('Common.date')}</span>
                                        <br/>
                                            <span>{props.data.data.letter.date}</span>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default PreviewContent
