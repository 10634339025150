import React, { useState } from 'react';
import { PersonalDetials, ContactDetails, BankDetails, PaymentDetails, Settings } from './';
import style from './profile.module.scss';
import AlertMessage from '../common/AlertMessage';
import { useTranslation } from 'react-i18next';
import QrUpload from './QrUpload';
function EditContent(props) {
    console.log("editconent", props)
    const { t } = useTranslation()
    const [state, setState] = useState({
        EditPerosal: true,
        EditContact: true,
        EditBankDetails: true,
        EditPaymentDetials: true,
        EditWalletCode: true,
        EditSettingsDetails: true,
        loader: false
    });
    const notifyInintialState = {
        show: false,
        message: '',
        type: '',
        header: ''
    }
    const [notify, setNotify] = useState(notifyInintialState)
    const {
        EditPerosal,
        EditContact,
        EditBankDetails,
        EditWalletCode,
        EditPaymentDetials,
        EditSettingsDetails
    } = state
    const editButtonClick = (key) => {
        cancelbtn();
        setState(prev => ({
            ...prev,
            [key]: false
        }));

    }
    //cancel button click
    const cancelbtn = () => {
        Object.keys(state).forEach(i => {
            setState(prev => ({
                ...prev,
                [i]: true
            }));
        });
    }
    const notifyDissmissed = () => {
        setNotify(prev => ({
            ...prev,
            show: false
        }))
    }
    const showNotify = (type, header, message) => {
        setNotify(prev => ({
            ...prev,
            show: true,
            type: type,
            header: t('Common.' + header),
            message: t(message)
        }))
    }
    // cancelbtn();
    return (
        <div className={style.EditContent}>
            <PersonalDetials
                status={'EditPerosal'}
                change={editButtonClick}
                isEditable={EditPerosal}
                cancelbtn={cancelbtn}
                data={props.editData.personal_details.fields}
                updateSuccess={props.updateSuccess}
                stateKey="personal_details"
                showNotify={showNotify}
                getData={props.getData}

            />
            <ContactDetails
                status={'EditContact'}
                change={editButtonClick}
                isEditable={EditContact}
                cancelbtn={cancelbtn}
                data={props.editData.contact_details.fields}
                updateSuccess={props.updateSuccess}
                stateKey="contact_details"
                showNotify={showNotify}
                getData={props.getData}
            />
            <BankDetails
                status={'EditBankDetails'}
                change={editButtonClick}
                isEditable={EditBankDetails}
                cancelbtn={cancelbtn}
                data={props.editData.bank_details.fields}
                updateSuccess={props.updateSuccess}
                stateKey="bank_details"
                showNotify={showNotify}
                getData={props.getData}
            />
            <QrUpload
                status={'EditWalletCode'}
                change={editButtonClick}
                isEditable={EditWalletCode}
                cancelbtn={cancelbtn}
                data={props.editData.bank_details.fields}
                updateSuccess={props.updateSuccess}
                stateKey="wallet_code"
                showNotify={showNotify}
                getData={props.getData}
            />
            {props.editData?.payment_details?.fields && props.editData?.payment_details?.fields[0]?.options &&
                <PaymentDetails
                    status={'EditPaymentDetials'}
                    change={editButtonClick}
                    isEditable={EditPaymentDetials}
                    cancelbtn={cancelbtn}
                    data={props.editData.payment_details.fields}
                    updateSuccess={props.updateSuccess}
                    stateKey="payment_details"
                    showNotify={showNotify}
                    getData={props.getData}
                />
            }
            {props.editData.settings_details.fields &&
                <Settings
                    status={'EditSettingsDetails'}
                    change={editButtonClick}
                    isEditable={EditSettingsDetails}
                    cancelbtn={cancelbtn}
                    data={props.editData.settings_details.fields}
                    updateSuccess={props.updateSuccess}
                    stateKey="settings_details"
                    showNotify={showNotify}
                    getData={props.getData}
                />
            }
            {
                notify.show &&
                <AlertMessage
                    message={notify.message}
                    dismiss={notifyDissmissed}
                    type={notify.type}
                    header={notify.header}
                    show={notify.show}
                />
            }
        </div>
    )
}

export default EditContent
