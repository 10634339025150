import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import PageTitle from '../../../components/common/pageTitle';
import { withTranslation } from 'react-i18next';
import { PreviewContent } from '../../../components/registration';
import { withRouter } from 'react-router-dom';
import RegisterService from '../../../../service/Auth/Register';
import companyName from '../../../../store/mobxStore/companyName';
import { Spinner } from 'react-bootstrap';
import { AlertBs } from '../../../components/common';
class Preview extends PureComponent {
    constructor(props) {
         console.log('registerr',props)
        super(props)

        this.state = {
            loading: true,
            data: {},
            // alert: {
            //     show: false,
            //     message: "",
            //     varient: "",
            // },
            alertmessage:{
                message:'registration_completed_successfully',
                username:this.props.match.params.id
            },
            payment_method:''
        }
        this.username = this.props.match.params.useranme;
        this.service = new RegisterService();
        
    }
    
    componentDidMount() {
        const username = this.props.match.params.id;
        const payment_method=this.props.match.params.payment_method
        if(username&&payment_method){
            this.setState({
                payment_method:this.props.match.params.payment_method,
                
            })
        }
        console.log('username', username)
        this.service.registerSummary(username).then((res) => {

            if (res.status) {
                this.setState({
                    loading: false,
                    data: res.data,
                    // alert: {
                    //     show: true,
                    //     message: this.props.t('registration.registration_completed_successfully', { name: username }),
                    //     varient: 'success'
                    // }
                })
            } else {
                if (res.error.code === 1011) {
                    this.props.history.push('/login');
                } else if (res.error.code === 1037) {
                    this.props.history.push('/dashboard');
                    alert(this.props.t('Common.Email_Verification_Required', { id: this.username.id }));
                }
                else {
                    this.props.history.push('/logout');
                }
            }
        })
    }


    render() {
        const { t } = this.props;

        const { location } = this.props;
        // console.log('location',location.data.message)
        if (this.state.loading) {
            return <Spinner />
        }
        return (
            <div className={`h-100`}>
                <Helmet>
                    <title>{companyName.companyName} | {t('Sidemenu.letterPreview')}</title>
                </Helmet>
                <PageTitle
                    title="letterPreview"
                    buttonOn={false}
                />
                {
                    this.state &&
                    <>
                        <PreviewContent
                            // alertmessage={location.data ?? ''}
                            alertmessage={this.state.alertmessage}
                            data={this.state}
                           payment_method={this.state.payment_method}
                        />
                    </>

                }
            </div>
        )
    }
}

export default withTranslation()(withRouter(Preview))